import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import { Link } from "react-router-dom";
import laptopImg from "../../assets/images/headerbg.png";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS file for AOS
import MoveToTopButton from "../../components/moveToTopButton/MoveToTopButton";
import aboutus1 from "../../assets/images/aboutus.png";
import aboutus2 from "../../assets/images/aboutus2.jpg";
import aboutus3 from "../../assets/images/aboutus3.jpg";
import aboutus0 from "../../assets/images/aboutus0.png";

export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);

    AOS.init({
      duration: 1000, // Animation duration
      offset: 50, // Offset (in pixels) from the original trigger point
      delay: 100, // Delay (in milliseconds) for the animation to start
    });
  }, []);

  return (
    <div>
      <Layout>
        <div className="">
          {/* <div className="bg-[#e2e6e9] py-4 md:pl-8 pl-3 text-sm md:text-base tracking-wide">
            <span>
              <Link to="/">Home</Link> /{" "}
              <span className="text-[#0b3b72]">About Us</span>
            </span>
          </div> */}
          <div className="md:px-10 p-6 mt-0">
            <div
              className="text-center mt-12 flex flex-col items-center justify-center"
              data-aos="fade-up"
            >
              <p
                className="text-[#0085ff] text-[17px] font-medium text-center mb-1"
                data-aos="fade-up"
              >
                Indutry Leader
              </p>
              <h2 className="md:text-4xl text-3xl font-medium tracking-wide text-black">
                <span className="font-bold text-[#0085ff]">About</span> Us
              </h2>
              <p className="text-[#535353] mt-4 tracking-wider md:w-[600px] w-[100%]">
                We combine innovation and experience to provide tailored IT
                services that meet your unique needs.
              </p>
              <div className="relative bg-[#0085ff] h-[6px] w-[140px] mt-4 rounded-md overflow-hidden">
                <div className="absolute h-[6px] w-[10px] bg-white top-0 left-0 rounded-md heading-underline"></div>
              </div>
            </div>
            <div className="mt-16">
              <div className="hidden lg:block" data-aos="fade-up">
                <img src={laptopImg} alt="" className="md:h-auto h-[200px]" />
              </div>
              <div className="my-10">
                <div className="flex lg:flex-row flex-col-reverse items-center justify-around mt-16">
                  <div className="md:mt-16 mt-10 lg:w-[50%] w-[100%]">
                    <h4
                      className="md:font-bold font-semibold md:text-3xl text-[26px]"
                      data-aos="fade-up"
                    >
                      Story Behind Our{" "}
                      <span className="text-[#0085ff]">Firm</span>{" "}
                    </h4>
                    <p
                      className="text-[#6c6c6d] text-[17px] font-normal text-start mt-3"
                      data-aos="fade-up"
                    >
                      Founded in 1999, Lakecity Web Private Limited has grown
                      from a small startup into a leading IT company with a
                      global presence. Our 25-year journey has been marked by a
                      relentless pursuit of excellence and a commitment to
                      delivering value to our clients. We specialize in a wide
                      range of IT services, including software development, web
                      design, digital marketing, and IT consulting.Our mission
                      is to empower businesses with innovative technology
                      solutions that enhance their productivity and drive
                      growth. With a team of highly skilled professionals, we
                      have built a reputation for delivering high-quality,
                      reliable, and scalable IT solutions that meet the unique
                      needs of our clients.
                    </p>
                  </div>
                  <div className="lg:w-[50%] w-[100%] flex justify-center">
                    <img
                      src={aboutus0}
                      alt=""
                      className="md:h-[430px] md:w-[450px] h-[320px] w-[95%]"
                      data-aos="zoom-in-up"
                    />
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col items-center justify-around">
                  <div className="lg:w-[50%] w-[100%]">
                    <img
                      src={aboutus2}
                      alt=""
                      className="lg:h-[450px] lg:w-[500px] h-[350px] w-full"
                      data-aos="zoom-in-up"
                    />
                  </div>
                  <div className="lg:w-[50%] w-[100%] relative top-10">
                    <h4
                      className="md:font-bold font-semibold md:text-3xl text-[26px]"
                      data-aos="fade-up"
                    >
                      Developing{" "}
                      <span className="text-[#0085ff]">High-quality</span> Apps
                    </h4>
                    <p
                      className="text-[#6c6c6d] text-[17px] font-normal text-start mt-3"
                      data-aos="fade-up"
                    >
                      At Lakecity Web Private Limited, we pride ourselves on
                      developing high-quality mobile applications that combine
                      functionality with an outstanding user experience. Our
                      expert team utilizes the latest technologies and best
                      practices to create apps that are not only visually
                      appealing but also robust, secure, and scalable. From
                      initial concept to final deployment, we focus on every
                      detail to ensure that our apps meet the unique needs of
                      our clients and their users. Whether it’s for Android or
                      iOS platforms, our applications are designed to perform
                      seamlessly, helping businesses to engage their audience,
                      streamline operations, and drive growth.
                    </p>
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col-reverse items-center justify-around mt-16">
                  <div className="md:mt-16 mt-10 lg:w-[50%] w-[100%]">
                    <h4
                      className="md:font-bold font-semibold md:text-3xl text-[26px]"
                      data-aos="fade-up"
                    >
                      Providing <span className="text-[#0085ff]">Reliable</span>{" "}
                      Software
                    </h4>
                    <p
                      className="text-[#6c6c6d] text-[17px] font-normal text-start mt-3"
                      data-aos="fade-up"
                    >
                      At Lakecity Web Private Limited, our commitment to
                      excellence shines through in our provision of reliable
                      software solutions. We understand that dependable software
                      is the backbone of any successful business operation,
                      which is why we prioritize stability, security, and
                      performance in all our developments. Our team of skilled
                      professionals rigorously tests each product to ensure it
                      meets the highest standards of quality and reliability.
                      Whether it’s custom software tailored to your specific
                      needs or enterprise solutions designed for scalability,
                      our software delivers consistent performance, helping you
                      to maintain smooth operations and achieve your business
                      objectives with confidence.
                    </p>
                  </div>
                  <div className="lg:w-[50%] w-[100%] flex justify-center">
                    <img
                      src={aboutus3}
                      alt=""
                      className="md:h-[420px] md:w-[550px] h-[320px] w-full"
                      data-aos="zoom-in-up"
                    />
                  </div>
                </div>

                <div className="md:mt-16 mt-10">
                  <h4
                    className="md:font-bold font-semibold md:text-3xl text-[26px]"
                    data-aos="fade-up"
                  >
                    Supporting Our{" "}
                    <span className="text-[#0085ff]">Clients</span>
                  </h4>
                  <p
                    className="text-[#6c6c6d] text-[17px] font-normal text-start mt-3"
                    data-aos="fade-up"
                  >
                    At Lakecity Web Private Limited, client support is at the
                    heart of everything we do. We believe that our relationship
                    with clients extends beyond project completion, and we are
                    committed to providing ongoing support to ensure their
                    continued success. Our dedicated support team is available
                    to address any issues, answer questions, and provide timely
                    solutions. We offer comprehensive maintenance services to
                    keep your systems running smoothly and efficiently. By
                    staying responsive to your needs and proactively addressing
                    potential challenges, we help you maximize the value of our
                    solutions and maintain a competitive edge in your industry.
                  </p>
                  <p
                    className="text-[#6c6c6d] text-[16px] font-normal text-start mt-3"
                    data-aos="fade-up"
                  >
                    Innovation is at the core of Lakecity Web Private Limited's
                    philosophy. We are committed to exploring new technologies
                    and developing forward-thinking solutions that meet the
                    evolving needs of our clients. Our research and development
                    team continuously investigates emerging trends and
                    advancements in the IT sector to create innovative products
                    and services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MoveToTopButton />
      </Layout>
    </div>
  );
}
