import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import { Link } from "react-router-dom";
import teamPerson1 from "../../assets/images/teamPerson1.png";
import teamPerson2 from "../../assets/images/teamPerson2.png";
import teamPerson3 from "../../assets/images/teamPerson3.png";
import teamPerson4 from "../../assets/images/teamPerson4.png";
import teamPerson5 from "../../assets/images/teamPerson5.png";
import teamPerson6 from "../../assets/images/teamPerson6.png";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS file for AOS
import MoveToTopButton from "../../components/moveToTopButton/MoveToTopButton";
import businessDiscussion from "../../assets/images/businesspeople-working-laptop-office.jpg"

const teamMembers = [
  {
    id: 1,
    name: "John Doe",
    position: "Founder & CEO",
    image: teamPerson1,
  },
  {
    id: 2,
    name: "Jane Smith",
    position: "Chief Technology Officer",
    image: teamPerson2,
  },
  {
    id: 3,
    name: "Emily Johnson",
    position: "Head of Marketing",
    image: teamPerson3,
  },
  {
    id: 4,
    name: "Michael Brown",
    position: "Lead Developer",
    image: teamPerson4,
  },
  {
    id: 5,
    name: "Sarah Davis",
    position: "Product Manager",
    image: teamPerson5,
  },
  {
    id: 6,
    name: "David Wilson",
    position: "UX/UI Designer",
    image: teamPerson6,
  },
];

export default function OurTeam() {
  useEffect(() => {
    window.scrollTo(0,0);
    AOS.init({
      duration: 1000, // Animation duration
      offset: 50, // Offset (in pixels) from the original trigger point
      delay: 100, // Delay (in milliseconds) for the animation to start
    });
  }, []);

  return (
    <div>
      <Layout>
        <div className="">
          {/* <div className="bg-[#e2e6e9] py-4 md:pl-8 pl-3 text-sm md:text-base tracking-wide">
            <span>
              <Link to="/">Home</Link> /{" "}
              <span className="text-[#0b3b72]">Our Team</span>
            </span>
          </div> */}
          <div className="md:px-10 p-5">
            <div
              className="text-center mt-12 flex flex-col items-center justify-center"
              data-aos="fade-up"
            >
              <p
                className="text-[#0085ff] text-[17px] font-medium text-center mb-1"
                data-aos="fade-up"
              >
                Meet Our Team
              </p>
              <h2 className="md:text-4xl text-3xl font-medium tracking-wide text-black">
                {" "}
                Our Awesome{" "}
                <span className="font-bold text-[#0085ff]">Team</span>
              </h2>
              <p className="text-[#535353] mt-4 tracking-wider md:w-[550px] w-[95%]">
              Our team of skilled professionals brings expertise, creativity, and dedication to every project.
              </p>
              <div className="relative bg-[#0085ff] h-[6px] w-[140px] mt-4 rounded-md overflow-hidden">
                <div className="absolute h-[6px] w-[10px] bg-white top-0 left-0 rounded-md heading-underline"></div>
              </div>
            </div>
            {/* <div className="mt-16 flex justify-around flex-wrap gap-10">
              {teamMembers.map((member) => {
                return (
                  <div className="relative group" data-aos="zoom-in-up">
                    <img
                      src={member.image}
                      alt=""
                      className="md:h-[400px] h-[380px] md:w-[350px] w-[320px]"
                    />
                    <div className="bg-[rgb(11,59,114,0.6)] md:h-[400px] h-[380px] md:group-hover:w-[350px] group-hover:w-[320px] duration-500 absolute top-0 w-[0px] flex flex-col justify-end overflow-hidden">
                      <span className="text-white text-xl font-semibold px-6 whitespace-nowrap w-full">
                        {member.name}
                      </span>
                      <span className="text-white text-lg font-semibold px-6 whitespace-nowrap pb-6">
                        {member.position}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div> */}
            <div className="mt-16 gap-10">
              {/* <div className="flex justify-center">
                <img src={businessDiscussion} alt="" className="h-[500px] w-[800px]" />
              </div> */}
              <div className="my-10">
               
                <div className="flex lg:flex-row flex-col items-center justify-around">
                  <div className="lg:w-[50%] w-[100%]">
                    <img
                      src={businessDiscussion}
                      alt=""
                      className="lg:h-[400px] lg:w-[650px] h-[240px] w-full rounded-md"
                      data-aos="zoom-in-up"
                    />
                  </div>
                  <div className="lg:w-[46%] w-[100%] relative md:mt-0 mt-10">
                    <h4
                      className="md:font-bold font-semibold md:text-3xl text-[26px]"
                      data-aos="fade-up"
                    >
                      Meet Our {" "}
                      <span className="text-[#0085ff]">Experts</span>
                    </h4>
                    <p
                      className="text-[#6c6c6d] text-[17px] font-normal text-start mt-3"
                      data-aos="fade-up"
                    >
                      At Lakecity Web Private Limited, our team of experts is the cornerstone of our success. Each member brings a wealth of experience and a deep passion for their craft, ensuring that we deliver top-tier solutions to our clients. Our experts come from diverse backgrounds in technology, design, and strategy, allowing us to approach every project with a fresh perspective and innovative ideas. We believe in continuous learning and professional growth, which is why our team stays at the forefront of industry trends and technological advancements.
                    </p>
                  </div>
                </div>
               

                <div className="md:mt-16 mt-10">
                  <h4
                    className="md:font-bold font-semibold md:text-3xl text-[26px]"
                    data-aos="fade-up"
                  >
                    Collaborative and {" "}
                    <span className="text-[#0085ff]">Creative Minds</span>
                  </h4>
                  <p
                    className="text-[#6c6c6d] text-[17px] font-normal text-start mt-3"
                    data-aos="fade-up"
                  >
                    At the core of Lakecity Web Private Limited is a team of collaborative and creative minds who thrive on innovation and teamwork. Our success is built on the synergy that comes from bringing together diverse perspectives, skill sets, and experiences. Each member of our team contributes their unique expertise, whether it’s in software development, design, marketing, or strategy, creating a dynamic environment where ideas flow freely, and creativity flourishes.
                  </p>
                  <p
                    className="text-[#6c6c6d] text-[16px] font-normal text-start mt-3"
                    data-aos="fade-up"
                  >
                    We believe that the best solutions emerge from collaboration. By working closely together, we ensure that every project benefits from the collective intelligence and creativity of our team. This collaborative spirit allows us to approach problems from multiple angles, uncovering opportunities and crafting solutions that are not only effective but also inventive.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="text-[#0b3b72] text-2xl text-center mt-16"
              data-aos="fade-up"
            >
              We have more than <span className="font-bold">460+</span> <br />{" "}
              <span className="font-bold">Happy Customers</span> with
              satisfaction
            </div>
          </div>
        </div>
        <MoveToTopButton />
      </Layout>
    </div>
  );
}
