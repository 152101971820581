import React, { useState } from "react";
import { Link } from "react-router-dom";

import web_logo from "../../assets/images/lake_web_logo.png"

export default function Navbar() {
  const [toggleNav, setToggleNav] = useState(false);

  const handleToggleNav = () => {
    setToggleNav(!toggleNav);
  };

  return (
    <div className="flex justify-between h-[90px] w-full items-center bg-white md:px-10 sticky top-0 z-40 shadow-sm">
      <div className=" md:ml-2 ml-0">
        <Link to="/"><img src={web_logo} className="md:h-[120px] md:w-[140px] h-[100px] w-[120px]" alt="" /></Link>
      </div>
      <div className="lg:block hidden">
        <ul className="flex justify-around gap-10 font-normal tracking-wide">
          <li className="cursor-pointer text-[#0b3b72] font-medium">
            <Link to="/">Home</Link>
          </li>
          <li className="cursor-pointer text-[#0b3b72] font-medium">
            <Link to="/our-services">Services</Link>
          </li>
          <li className="cursor-pointer text-[#0b3b72] font-medium">
            <Link to="/our-projects">Projects</Link>
          </li>
          <li className="cursor-pointer text-[#0b3b72] font-medium">
            {" "}
            <Link to="/our-team">Our Team</Link>
          </li>
          <li className="cursor-pointer text-[#0b3b72] font-medium">
            <Link to="/about-us">About us</Link>
          </li>
          <li className="cursor-pointer text-[#0b3b72] font-medium">
            <Link to="/contact-us">Contact us</Link>
          </li>
        </ul>
      </div>
      <div className="block lg:hidden">
        <div className="relative top-1 lg:top-0 md:mr-0 mr-4">
          <button onClick={handleToggleNav}>
            {toggleNav ? (
              <i className="fa fa-times"></i>
            ) : (
              <i className="fa fa-bars"></i>
            )}
          </button>
        </div>
        {
          <div
            className={`absolute right-0 bg-[#edeeef] ${
              toggleNav ? "w-[250px]" : "w-[0px]"
            } transition-all mt-[34px] shadow-md h-[90vh] duration-300 overflow-hidden whitespace-nowrap tracking-wider`}
          >
            <ul className="flex flex-col justify-around gap-6 text-[rgb(11,59,114)] px-6 py-10 font-medium">
              <li className="cursor-pointer hover:text-[#0b3b72] hover:border-[#0b3b72] hover:border-b">
                <Link to="/">Home</Link>
              </li>
              <li className="cursor-pointer hover:text-[#0b3b72] hover:border-[#0b3b72] hover:border-b">
                <Link to="/our-services">Services</Link>
              </li>
              <li className="cursor-pointer hover:text-[#0b3b72] hover:border-[#0b3b72] hover:border-b">
                <Link to="/our-projects">Projects</Link>
              </li>
              <li className="cursor-pointer hover:text-[#0b3b72] hover:border-[#0b3b72] hover:border-b">
                {" "}
                <Link to="/our-team">Our Team</Link>
              </li>
              <li className="cursor-pointer hover:text-[#0b3b72] hover:border-[#0b3b72] hover:border-b">
                <Link to="/about-us">About us</Link>
              </li>
              <li className="cursor-pointer hover:text-[#0b3b72] hover:border-[#0b3b72] hover:border-b">
                <Link to="/contact-us">Contact us</Link>
              </li>
            </ul>
          </div>
        }
      </div>
    </div>
  );
}
