import React, { useEffect } from "react";
import googleBtn from "../../assets/images/googleplay_button.png";
import appleBtn from "../../assets/images/appstore_button.png";
import "../../styles/tiltTriangle.css";
import AOS from 'aos';
import 'aos/dist/aos.css';  // Import the CSS file for AOS
import { Link } from "react-router-dom";

export default function Footer() {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      offset: 50, // Offset (in pixels) from the original trigger point
      delay: 100, // Delay (in milliseconds) for the animation to start
    });
  }, []);

  return (
    <div>
      {/* Footer */}
      <div className="relative tracking-wide">
      <div class="custom-shape-divider-top-1721987059">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
    </svg>
</div>
        <div className="xl:h-[550px] h-auto md:p-16 p-10 bg-[#0b3b72] flex lg:flex-row flex-col justify-center">
          <div className="flex items-center mt-[100px]">
            <div className="flex lg:flex-row flex-col justify-around gap-16">
              <div className="lg:w-[25%] w-full">
                <h3 className="text-white md:font-bold font-semibold text-2xl" data-aos="fade-up">
                  LAKECITY WEB
                </h3>
                <p className="text-white text-[16px] mt-4" data-aos="fade-up">
                Our mission is simple yet profound: to empower businesses with innovative technology solutions that drive success. We are dedicated to understanding our clients’ unique challenges and providing tailored solutions.
                </p>
              </div>
              <div className="flex lg:flex-row flex-col gap-20">
                <div className=" text-white">
                  <h6 className="text-2xl md:font-bold font-semibold" data-aos="fade-up">QuickLinks</h6>
                  <ul>
                    <li className="md:font-medium font-base mt-2" data-aos="fade-up"><Link to="/">Home</Link></li>
                    <li className="md:font-medium font-base mt-2" data-aos="fade-up"><Link to="/our-services">Services</Link></li>
                    <li className="md:font-medium font-base mt-2" data-aos="fade-up"><Link to="/our-projects">Projects</Link></li>
                    <li className="md:font-medium font-base mt-2" data-aos="fade-up"><Link to="/our-team">Our Team</Link></li>
                  </ul>
                </div>
                <div className=" text-white">
                  <h6 className="text-2xl md:font-bold font-semibold" data-aos="fade-up">Other Links</h6>
                  <ul>
                  <li className="md:font-medium font-base mt-2" data-aos="fade-up"><Link to="/">Home</Link></li>
                    <li className="md:font-medium font-base mt-2" data-aos="fade-up"><Link to="/our-services">Services</Link></li>
                    <li className="md:font-medium font-base mt-2" data-aos="fade-up"><Link to="/about-us">About Us</Link></li>
                    <li className="md:font-medium font-base mt-2" data-aos="fade-up"><Link to="/contact-us">Contact Us</Link></li>
                  </ul>
                </div>
              </div>
              <div className="lg:w-[25%] w-full text-white">
                <h6 className="text-2xl md:font-bold font-semibold" data-aos="fade-up">Social Links</h6>
                <p className="text-white text-[15px] md:mt-4 mt-2" data-aos="fade-up">
                Connect with us on social media to stay updated on the latest news, insights, and innovations from Lakecity Web.
                </p>
                <div className="mt-4 flex gap-4 text-2xl" data-aos="fade-up">
                      {/* <i className="fa fa-facebook"></i> */}
                      <Link to="https://www.linkedin.com/company/lakecitywebprivatelimited/about/?viewAsMember=true" target="_blank"> <i className="fa fa-linkedin"></i> </Link>
                      <Link to="" target="_blank"> <i className="fa fa-whatsapp"></i><span className="text-lg relative -top-1 ml-2">+91 8003990546</span></Link>
                      {/* <i className="fa fa-instagram"></i> */}
                      {/* <i className="fa fa-twitter"></i> */}
                    </div>
                {/* <img
                  src={googleBtn}
                  alt=""
                   data-aos="fade-up"
                  className="md:h-[40px] md:w-[120px] w-[150px] mt-6"
                />
                <img
                  src={appleBtn}
                  alt=""
                   data-aos="fade-up"
                  className="md:h-[40px] md:w-[120px] w-[150px] md:mt-6 mt-2"
                /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-col justify-center items-center py-3 md:px-12 px-10 text-center" >
          <p className="text-black md:text-base text-sm">© 1999 - 2024 Lakecity Web Private Limited. All Rights Reserved</p>
          {/* <div className="flex md:gap-6 gap-4">
            <div className="h-[50px] w-[50px] rounded-full flex justify-center items-center bg-white">
              <i className="fa fa-facebook"></i>
            </div>
            <div className="h-[50px] w-[50px] rounded-full flex justify-center items-center bg-white">
              <i className="fa fa-linkedin"></i>
            </div>
            <div className="h-[50px] w-[50px] rounded-full flex justify-center items-center bg-white">
              <i className="fa fa-instagram"></i>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
