import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Homepage from '../pages/homepage/Homepage'
import Service from '../pages/servicesPage/Service'
import Projects from '../pages/projectPages/Projects'
import AboutUs from '../pages/aboutPage/AboutUs'
import ContactUs from '../pages/contactPage/ContactUs'
import OurTeam from '../pages/teamPage/OurTeam'

export default function AllRoutes() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Homepage />}></Route>
        <Route path='/our-services' element={<Service />}></Route>
        <Route path='/our-projects' element={<Projects />}></Route>
        <Route path='/our-team' element={<OurTeam />}></Route>
        <Route path='/about-us' element={<AboutUs />}></Route>
        <Route path='/contact-us' element={<ContactUs />}></Route>
      </Routes>
    </div>
  )
}
