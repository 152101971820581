import React, { useEffect } from "react";
import carousel1 from "../../assets/images/carouselImg1.png";
import carousel2 from "../../assets/images/carouselImg2.png";
import carousel3 from "../../assets/images/carouselImg3.png";
import carousel4 from "../../assets/images/carouselImg4.png";
import Layout from "../../components/layout/Layout";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';  // Import the CSS file for AOS
import MoveToTopButton from "../../components/moveToTopButton/MoveToTopButton";

export default function Projects() {
  const projects = [
    {
      id: 1,
      title: "Weather Dashboard",
      description:
        "A comprehensive weather dashboard application that provides current weather conditions, forecasts, and alerts for multiple locations. Built with React and integrated with the OpenWeather API, this project aims to deliver accurate and up-to-date weather information with a user-friendly interface.",
      image: carousel1,
    },
    {
      id: 2,
      title: "E-Commerce Platform",
      description:
        "A full-featured e-commerce platform that supports product listings, shopping cart functionality, user authentication, and secure payment processing. Developed using Node.js, Express, and MongoDB, the platform is designed to handle high traffic and provide a seamless shopping experience.",
      image: carousel2,
    },
    {
      id: 3,
      title: "Personal Portfolio",
      description:
        "A personal portfolio website showcasing my skills, projects, and professional experience. Built with HTML, CSS, and JavaScript, the portfolio includes sections for an about me, projects, blog, and contact form. The design is responsive and optimized for different devices and screen sizes.",
      image: carousel3,
    },
    {
      id: 4,
      title: "Task Management App",
      description:
        "A task management application that allows users to create, edit, and delete tasks, set due dates, and organize tasks into projects. Developed using Angular and Firebase, the app includes real-time updates and synchronization across devices, providing an efficient way to manage tasks and increase productivity.",
      image: carousel4,
    },
    {
      id: 5,
      title: "Social Media Analytics",
      description:
        "A social media analytics tool that aggregates data from various social media platforms to provide insights into user engagement, reach, and performance metrics. Built with Python and Django, the tool includes interactive dashboards and visualizations created with D3.js to help users make data-driven decisions.",
      image: carousel1,
    },
    {
      id: 6,
      title: "Online Learning Platform",
      description:
        "An online learning platform that offers a variety of courses across different subjects. Features include video lectures, quizzes, discussion forums, and progress tracking. Developed using Ruby on Rails and PostgreSQL, the platform aims to provide a comprehensive and interactive learning experience for users.",
      image: carousel2,
    },
    {
      id: 7,
      title: "Fitness Tracker",
      description:
        "A fitness tracking application that allows users to log workouts, track progress, set fitness goals, and analyze performance over time. Built with React Native for cross-platform compatibility, the app integrates with various health APIs to provide accurate tracking and personalized fitness recommendations.",
      image: carousel3,
    },
    {
      id: 8,
      title: "Recipe Finder",
      description:
        "A recipe finder application that helps users discover new recipes based on ingredients they have at home. Developed using Vue.js and integrated with the Edamam API, the app features advanced search capabilities, nutritional information, and the ability to save favorite recipes for easy access.",
      image: carousel4,
    },
    {
      id: 9,
      title: "Travel Blog",
      description:
        "A travel blog website that showcases travel stories, tips, and guides from around the world. Built with WordPress, the blog includes high-quality images, interactive maps, and a commenting system to engage with readers. The design focuses on providing an immersive reading experience.",
      image: carousel1,
    },
  ];

  useEffect(() => {
    window.scrollTo(0,0);
    AOS.init({
      duration: 1000, // Animation duration
      offset: 50, // Offset (in pixels) from the original trigger point
      delay: 100, // Delay (in milliseconds) for the animation to start
    });
  }, []);

  return (
    <>
      <Layout>
        <div>
          {/* <div className="bg-[#e2e6e9] py-4 md:pl-8 pl-3 text-sm md:text-base tracking-wide">
            <span>
              <Link to="/">Home</Link> /{" "}
              <span className="text-[#0b3b72]">Projects</span>
            </span>
          </div> */}
          {/* Recent Projects */}
          <div className="lg:px-16 md:py-10">
          <div
              className="text-center mt-16 flex flex-col items-center justify-center"
              data-aos="fade-up"
            >
              <p
                className="text-[#0085ff] text-[17px] font-medium text-center mb-1"
                data-aos="fade-up"
              >
                Our Work
              </p>
              <h2 className="md:text-4xl text-3xl font-medium tracking-wide text-black">
                {" "}
                Our Recent{" "}
                <span className="font-bold text-[#0085ff]">Project</span>
              </h2>
              <p className="text-[#535353] mt-4 tracking-wider md:w-[600px] w-[95%]">
              Successfully completed projects that showcase our expertise and
              deliver tangible results.
              </p>
              <div className="relative bg-[#0085ff] h-[6px] w-[140px] mt-4 rounded-md overflow-hidden">
                <div className="absolute h-[6px] w-[10px] bg-white top-0 left-0 rounded-md heading-underline"></div>
              </div>
            </div>
            <div
              className="flex justify-center md:mt-20 mt-12 select-none"
            >
              <div className="lg:w-[90%] md:w-[90%] w-[96%] flex flex-wrap justify-around gap-10">
                {/* <Carousel
                  withIndicators
                  height={200}
                  slideSize={{ base: "80%", sm: "50%", md: "33.333333%" }}
                  slideGap={{ base: 0, sm: "lg" }}
                  loop
                  align="center"
                > */}
                  {projects.map((project) => {
                    return (
                      // <Carousel.Slide>
                        <div
                          className="h-[400px] w-[320px] relative group"
                          style={{ backgroundImage: `url(${project.image})` }}
                          data-aos = "zoom-in-up"
                        >
                          <div className="h-[400px] w-[320px] absolute px-6 py-10 bg-[rgba(73,71,71,0.8)] text-white group-hover:block hidden">
                            <h4 className="text-2xl font-semibold">
                              {project.title}
                            </h4>
                            <div className="">
                              <p className="mt-4">{project.description}</p>
                            </div>
                            {/* <div className="flex-1 flex-col justify-end">
                              <button className="border-2 text-sm h-[35px] w-[120px] border-[#0085ff] mt-6 text-white bg-[#0085ff]">
                                Read More
                              </button>
                            </div> */}
                          </div>
                        </div>
                      // </Carousel.Slide>
                    );
                  })}

                  {/* ...other slides */}
                {/* </Carousel> */}
              </div>
            </div>
          </div>
        </div>
        <MoveToTopButton />
      </Layout>
    </>
  );
}
