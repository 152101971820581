import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { Carousel } from "@mantine/carousel";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS file for AOS

// Images / Icons
// import headerbg from "../../assets/images/headerbg.png";
// import logo from "../../assets/images/codermeet_logo.png";
// import rightIcon from "../../assets/icons/rigthArrow.png";
// import discussion from "../../assets/images/discussion_img.png";
// import joyImage from "../../assets/images/joy_img.png";
import girlImage from "../../assets/images/girlImage.png";
import carousel1 from "../../assets/images/carouselImg1.png";
import carousel2 from "../../assets/images/carouselImg2.png";
import carousel3 from "../../assets/images/carouselImg3.png";
import carousel4 from "../../assets/images/carouselImg4.png";
import join1 from "../../assets/images/join1.png";
import join2 from "../../assets/images/join2.png";
import join3 from "../../assets/images/join3.png";
import join4 from "../../assets/images/join4.png";
import join5 from "../../assets/images/join5.png";
import join6 from "../../assets/images/join6.png";
import join7 from "../../assets/images/join7.png";
import join8 from "../../assets/images/join8.png";
import join9 from "../../assets/images/join9.png";
import join10 from "../../assets/images/join104.png";
import join11 from "../../assets/images/join11.png";
import join from "../../assets/images/join.png";
import about from "../../assets/images/about.png";
import socialMedia from "../../assets/icons/social-media.png";
import webServices from "../../assets/icons/web-services.png";
import internet from "../../assets/icons/internet.png";
import growth from "../../assets/icons/growth.png";
import rotateCircle from "../../assets/icons/circle.png";
import newMoon from "../../assets/icons/new-moon.png";
// import plus from "../../assets/icons/add.png";
import triangle from "../../assets/icons/sharpen.png";
import processImage from "../../assets/images/process.png";
import reasearchImage from "../../assets/images/qualitative-research.png";
import codingImage from "../../assets/images/coding.png";
import maintainance from "../../assets/images/maintainece.png";

// Css files
import "../../styles/triangleshape.css";
import "../../styles/animations.css";
import MoveToTopButton from "../../components/moveToTopButton/MoveToTopButton";
import { Link, useNavigate } from "react-router-dom";

const projects = [
  {
    id: 1,
    title: "Weather Dashboard",
    description:
      "A comprehensive weather dashboard application that provides current weather conditions, forecasts, and alerts for multiple locations. Built with React and integrated with the OpenWeather API, this project aims to deliver accurate and up-to-date weather information with a user-friendly interface.",
    image: carousel1,
  },
  {
    id: 2,
    title: "E-Commerce Platform",
    description:
      "A full-featured e-commerce platform that supports product listings, shopping cart functionality, user authentication, and secure payment processing. Developed using Node.js, Express, and MongoDB, the platform is designed to handle high traffic and provide a seamless shopping experience.",
    image: carousel2,
  },
  {
    id: 3,
    title: "Personal Portfolio",
    description:
      "A personal portfolio website showcasing my skills, projects, and professional experience. Built with HTML, CSS, and JavaScript, the portfolio includes sections for an about me, projects, blog, and contact form. The design is responsive and optimized for different devices and screen sizes.",
    image: carousel3,
  },
  {
    id: 4,
    title: "Task Management App",
    description:
      "A task management application that allows users to create, edit, and delete tasks, set due dates, and organize tasks into projects. Developed using Angular and Firebase, the app includes real-time updates and synchronization across devices, providing an efficient way to manage tasks and increase productivity.",
    image: carousel4,
  },
  {
    id: 5,
    title: "Social Media Analytics",
    description:
      "A social media analytics tool that aggregates data from various social media platforms to provide insights into user engagement, reach, and performance metrics. Built with Python and Django, the tool includes interactive dashboards and visualizations created with D3.js to help users make data-driven decisions.",
    image: carousel1,
  },
  {
    id: 6,
    title: "Online Learning Platform",
    description:
      "An online learning platform that offers a variety of courses across different subjects. Features include video lectures, quizzes, discussion forums, and progress tracking. Developed using Ruby on Rails and PostgreSQL, the platform aims to provide a comprehensive and interactive learning experience for users.",
    image: carousel2,
  },
  // {
  //   id: 7,
  //   title: "Fitness Tracker",
  //   description:
  //     "A fitness tracking application that allows users to log workouts, track progress, set fitness goals, and analyze performance over time. Built with React Native for cross-platform compatibility, the app integrates with various health APIs to provide accurate tracking and personalized fitness recommendations.",
  //   image: carousel3,
  // },
  // {
  //   id: 8,
  //   title: "Recipe Finder",
  //   description:
  //     "A recipe finder application that helps users discover new recipes based on ingredients they have at home. Developed using Vue.js and integrated with the Edamam API, the app features advanced search capabilities, nutritional information, and the ability to save favorite recipes for easy access.",
  //   image: carousel4,
  // },
  // {
  //   id: 9,
  //   title: "Travel Blog",
  //   description:
  //     "A travel blog website that showcases travel stories, tips, and guides from around the world. Built with WordPress, the blog includes high-quality images, interactive maps, and a commenting system to engage with readers. The design focuses on providing an immersive reading experience.",
  //   image: carousel1,
  // },
  // {
  //   id: 10,
  //   title: "Event Management System",
  //   description:
  //     "An event management system that streamlines the process of planning and organizing events. Features include event scheduling, attendee registration, ticketing, and communication tools. Developed using Laravel and MySQL, the system is designed to handle events of all sizes and ensure smooth execution.",
  //   image: carousel2,
  // },
];

const services = [
  {
    title: "Mobile App Development",
    description:
      "Creating mobile applications for Android and iOS platforms, designed for excellent user experiences and high performance.",
    icon: "fa fa-mobile",
    color: "#D0E3F4", // Soft Light Blue
  },
  {
    title: "Web Development",
    description:
      "Building responsive and modern websites tailored to your business needs, ensuring a seamless user experience.",
    icon: "fa fa-code",
    color: "#C5E3A0", // Soft Green
  },
  {
    title: "Marketing Strategy",
    description:
      "Crafting comprehensive marketing strategies to boost your brand's visibility and reach your target audience effectively.",
    icon: "fa fa-pie-chart",
    color: "#6EC1E4", // Soft Blue
  },
  {
    title: "Business Development Strategy",
    description:
      "Designing strategic plans to drive business growth, enhance market presence, and increase revenue streams.",
    icon: "fa fa-cogs",
    color: "#F6C1C1", // Soft Pink
  },
  {
    title: "Firm Differentiation Strategy",
    description:
      "Creating unique value propositions to set your business apart from competitors and attract your ideal customers.",
    icon: "fa fa-shopping-cart",
    color: "#F7E1A0", // Soft Yellow
  },
  {
    title: "Social Media Planning",
    description:
      "Developing strategic social media plans to engage your audience, build brand loyalty, and increase online presence.",
    icon: "fa fa-search",
    color: "#B8E2D9", // Soft Teal
  },
];

export default function Homepage() {
  const navigate = useNavigate();

  useEffect(() => {
    // window.scrollTo(0, 0);
    AOS.init({
      duration: 1000, // Animation duration
      offset: 50, // Offset (in pixels) from the original trigger point
      delay: 100, // Delay (in milliseconds) for the animation to start
    });
  }, []);

  return (
    <div>
      <Layout>
        <div className="">
          {/* Header */}
          <div className="lg:h-[90vh] h-auto lg:bg-cover bg-none flex lg:flex-row flex-col justify-around items-center md:mt-0 mt-10 lg:pr-24 lg:pl-16 pl-6 md:py-10 px-5 relative">
            {/* <img src={plus} className="h-[30px] animated-image1" alt="" /> */}
            <img
              src={rotateCircle}
              className="h-[30px] animated-image2 z-20"
              alt=""
            />
            <img
              src={triangle}
              className="h-[30px] animated-image1 z-20 hidden md:block"
              alt=""
            />
            <div className="lg:w-[50%] w-[100%]">
              <div
                className="lg:w-[600px] w-full relative md:-top-8"
                data-aos="fade-up"
              >
                <img
                  src={newMoon}
                  alt=""
                  className="md:h-[40px] h-[30px] mb-4 icon-rotate"
                />
                <p className="text-[#0085ff] text-[17px] font-medium mb-2">
                  Welcome to Lakecity Web Private Limited
                </p>
                <h1 className="lg:text-[60px] md:text-4xl text-3xl lg:font-bold font-semibold md:leading-[65px] leading-[40px] tracking-wide">
                  Celebrating 25 Years of Excellence in IT
                </h1>
                <p className="text-[#535353] mt-4 tracking-wider">
                  At Lakecity Web Private Limited, we are proud to celebrate 25
                  years of innovation, excellence, and customer satisfaction in
                  the IT sector. Since our inception, we have been committed to
                  delivering cutting-edge technology solutions that drive
                  business success and transform industries.
                </p>
                <div className="flex flex-col md:flex-row gap-4 mt-6">
                  <button
                    className="bg-[#0085ff] text-white rounded-sm text-[14px] md:text-base md:h-[38px] h-[36px] w-[140px] md:w-[150px] hover:bg-gradient-to-tr to-[#0085ff] from-[#5dabf4]"
                    onClick={() => {
                      navigate("/contact-us");
                    }}
                  >
                    Contact Us{" "}
                  </button>
                  {/* <button className="border-[grey] border-2 rounded-sm md:h-[38px] h-[36px] text-[14px] md:text-base w-[140px] md:w-[150px] flex gap-2 items-center justify-center">
                  Learn Now{" "}
                  <img
                    src={rightIcon}
                    className="h-[20px] w-[20px]"
                    alt=""
                  />
                </button> */}
                </div>
              </div>
            </div>
            <div className="relative lg:w-[600px] w-[100%] h-full hidden lg:block">
              <img
                src={join10}
                alt=""
                className="absolute top-[150px] left-64 z-10 h-[220px]"
                data-aos="zoom-in-up"
              />
              <img
                src={join1}
                alt=""
                className="absolute top-10 left-[200px] z-10 h-[100px]"
                data-aos="zoom-in-down"
              />
              <img
                src={join2}
                alt=""
                className="absolute top-[70px] left-[480px] z-10 h-[200px]"
                data-aos="zoom-in-down"
              />
              <img
                src={join3}
                alt=""
                className="absolute top-[320px] left-[500px] z-10"
                data-aos="zoom-in-up"
              />
              <img
                src={join4}
                alt=""
                className="absolute top-[410px] left-[350px] z-10 h-[120px]"
                data-aos="zoom-in-up"
              />
              <img
                src={join6}
                alt=""
                className="absolute top-0 left-80 z-10 h-[120px]"
                data-aos="zoom-in-down"
              />
              <img
                src={join5}
                alt=""
                className="absolute top-[400px] left-40 z-10 h-[150px]"
                data-aos="zoom-in-up"
              />
              <img
                src={join7}
                alt=""
                className="absolute top-[350px] left-20 z-10"
                data-aos="zoom-in-right"
              />
              <img
                src={join8}
                alt=""
                className="absolute top-24 left-20 z-10"
                data-aos="zoom-in-right"
              />
              <img
                src={join9}
                alt=""
                className="absolute top-60 left-20 z-10"
                data-aos="zoom-in-right"
              />
              <img
                src={join11}
                alt=""
                className="absolute top-20 left-0 z-0"
                data-aos="zoom-in"
              />
            </div>
            <div
              className="block lg:hidden relative -left-10 lg:mt-0 mt-20 mb-6"
              data-aos="zoom-in-up"
            >
              <img src={join} alt="" />
            </div>
          </div>

          <hr />

          {/* Section 2 */}
          <div className="flex flex-col items-center py-10">
            <div
              className="text-center mt-16 flex flex-col items-center justify-center"
              data-aos="fade-up"
            >
              <p
                className="text-[#0085ff] text-[17px] font-medium text-center mb-1"
                data-aos="fade-up"
              >
                Our steps to success
              </p>
              <h2 className="md:text-4xl text-3xl font-medium tracking-wide text-black">
                {" "}
                Our Working{" "}
                <span className="font-bold text-[#0085ff]">Process</span>
              </h2>
              <p className="text-[#535353] mt-4 tracking-wider md:w-[550px] w-[95%]">
                Our working process is designed to ensure efficiency,
                transparency, and client satisfaction.
              </p>
              <div className="relative bg-[#0085ff] h-[6px] w-[140px] mt-4 rounded-md overflow-hidden">
                <div className="absolute h-[6px] w-[10px] bg-white top-0 left-0 rounded-md heading-underline"></div>
              </div>
            </div>
            <div className="flex flex-wrap gap-y-6 justify-around items-center md:my-36 mt-10 w-full">
              <div
                className="md:w-[260px] w-[290px] text-center h-[320px] flex flex-col items-center bg-[#e7e7fa] py-6 px-4 shadow-sm rounded-md hover:bg-[#0085ff] hover:text-white group"
                data-aos="zoom-in-up"
              >
                <div className="border-dashed border-[#e7e7fa] border-2 rounded-full h-[75px] w-[75px] flex justify-center items-center bg-white group-hover:border-solid">
                  <img
                    src={reasearchImage}
                    alt=""
                    className="h-[40px] w-[40px]"
                  />
                </div>
                <h5 className="text-xl text-center font-semibold mt-3">
                  Understanding Your Needs
                </h5>
                <p className="text-[#535353] group-hover:text-white tracking-wider mt-1 text-center">
                  We begin with a detailed consultation to understand your
                  business objectives, challenges, and requirements.
                </p>
              </div>
              <div
                className="md:w-[260px] w-[290px] text-center h-[320px] flex flex-col items-center bg-[#d1ebf7] py-6 px-4 shadow-sm rounded-md hover:bg-[#0085ff] hover:text-white group"
                data-aos="zoom-in-up"
              >
                <div className="border-dashed border-[#81bef6] border-2 rounded-full h-[75px] w-[75px] flex justify-center items-center bg-white group-hover:border-solid">
                  <img
                    src={processImage}
                    alt=""
                    className="h-[40px] w-[40px]"
                  />
                </div>
                <h5 className="text-xl font-semibold mt-3">
                  Strategic Planning
                </h5>
                <p className="text-[#535353] group-hover:text-white tracking-wider mt-1 text-center">
                  We develop a detailed project proposal outlining the scope of
                  work, documentation, timelines, milestones, and budgets.
                </p>
              </div>
              <div
                className="md:w-[260px] w-[290px] h-[320px] text-center flex flex-col items-center bg-[#f3e1e1] py-6 px-4 shadow-sm rounded-md hover:bg-[#0085ff] hover:text-white group"
                data-aos="zoom-in-up"
              >
                <div className="border-dashed border-[#f4bebe] border-2 rounded-full h-[75px] w-[75px] flex justify-center items-center bg-white group-hover:border-solid">
                  <img src={codingImage} alt="" className="h-[40px] w-[40px]" />
                </div>
                <h5 className="text-xl font-semibold mt-3">
                  Development and Testing
                </h5>
                <p className="text-[#535353] group-hover:text-white tracking-wider mt-1 text-center">
                  We prioritize iterative development, allowing for flexibility
                  and continuous improvement throughout the project lifecycle.
                </p>
              </div>
              <div
                className="md:w-[260px] w-[290px] h-[320px] flex text-center flex-col items-center bg-[#dbf0dc] py-6 px-4 shadow-sm rounded-md hover:bg-[#0085ff] hover:text-white group"
                data-aos="zoom-in-up"
              >
                <div className="border-dashed border-[#c2f7c5] border-2 rounded-full h-[75px] w-[75px] flex justify-center items-center bg-white group-hover:border-solid">
                  <img src={socialMedia} alt="" className="h-[40px] w-[40px]" />
                </div>
                <h5 className="text-xl font-semibold mt-3">
                  Deployment and Delivery
                </h5>
                <p className="text-[#535353] group-hover:text-white tracking-wider mt-1 text-center">
                  Our team ensures a smooth transition, minimizing downtime and
                  ensuring your solution is up and running efficiently.
                </p>
              </div>
              <div
                className="md:w-[260px] w-[290px] text-center h-[320px] flex flex-col items-center bg-[#f7e1a0] py-6 px-4 shadow-sm rounded-md hover:bg-[#0085ff] hover:text-white group"
                data-aos="zoom-in-up"
              >
                <div className="border-dashed border-[#c2f7c5] border-2 rounded-full h-[75px] w-[75px] flex justify-center items-center bg-white group-hover:border-solid">
                  <img
                    src={maintainance}
                    alt=""
                    className="h-[40px] w-[40px]"
                  />
                </div>
                <h5 className="text-xl font-semibold mt-3">
                  Maintenance and Sustained Assistance
                </h5>
                <p className="text-[#535353] group-hover:text-white tracking-wider mt-1 text-center">
                  Ensuring ongoing support and maintenance to keep your systems
                  running smoothly and efficiently.
                </p>
              </div>
            </div>
          </div>

          {/* Section 3 */}
          <div className="flex xl:flex-row flex-col gap-6 justify-around items-center xl:h-[800px] h-auto md:p-10 p-3 py-10 mt-10 bg-[#f3f3f3]">
            <div className="lg:w-[48%] w-[90%] flex" data-aos="fade-up">
              <div className="relative lg:h-[500px] h-auto">
                <img
                  className="hover:scale-110 duration-300 md:rounded-none w-full h-[260px] md:h-[450px] m-auto"
                  src={about}
                  alt=""
                  data-aos="zoom-in-up"
                />
              </div>
            </div>
            <div className="lg:w-[40%] w-[96%] lg:mt-0 mt-10 px-2">
              <p
                className="text-[#0085ff] text-[17px] font-medium"
                data-aos="fade-up"
              >
                The Story Behind Our Firm
              </p>
              <div className="" data-aos="fade-up">
                <h2 className="md:text-4xl text-3xl font-bold tracking-wide mt-2">
                  A Legacy of{" "}
                  <span className="text-[#0085ff]">Innovation </span>
                </h2>
                <div className="relative bg-[#0085ff] h-[6px] w-[140px] mt-2 rounded-md overflow-hidden">
                  <div className="absolute h-[6px] w-[10px] bg-white top-0 left-0 rounded-md heading-underline"></div>
                </div>
              </div>
              <p
                className="text-[#535353] md:text-[17px] text-base mt-6 tracking-wider"
                data-aos="fade-up"
              >
                Founded in 1999, Lakecity Web Private Limited has grown from a
                small startup into a leading IT company with a global presence.
                Our 25-year journey has been marked by a relentless pursuit of
                excellence and a commitment to delivering value to our clients.
                We specialize in a wide range of IT services, including software
                development, web design, digital marketing, and IT consulting.
              </p>
              <p
                className="text-[#535353] md:text-[17px] text-base mt-4 tracking-wider"
                data-aos="fade-up"
              >
                Our mission is to empower businesses with innovative technology
                solutions that enhance their productivity and drive growth. With
                a team of highly skilled professionals, we have built a
                reputation for delivering high-quality, reliable, and scalable
                IT solutions that meet the unique needs of our clients.
              </p>
              <button
                className="text-white border-2 h-[40px] w-[160px] bg-[#0085ff] mt-10 hover:text-white hover:bg-[#0085ff]"
                data-aos="fade-up"
                onClick={() => {
                  navigate("/about-us");
                }}
              >
                Read More
              </button>
            </div>
          </div>

          {/* Our services */}
          {/* <div className="relative mt-10">
            <div class="custom-shape-divider-top-1721549991">
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M1200 0L0 0 598.97 114.72 1200 0z"
                  class="shape-fill"
                ></path>
              </svg>
            </div>
            <div className="xl:h-[1150px] h-[auto] bg-[#e2e6e9] flex flex-col justify-center items-center  md:p-10 py-10">
              <div className="mt-36">
                <p className="text-[#0085ff] text-[17px] font-medium text-center mb-1" data-aos="fade-up">
                  What We Provide
                </p>
                <h2 className="text-4xl font-semibold tracking-wide text-black" data-aos="fade-up">
                  {" "}
                  Our Services
                </h2>
              </div>
              <div className="flex flex-wrap justify-center w-[80%] md:gap-20 gap-10 mt-20 ">

                <div className="bg-[#1a4678] w-[300px] rounded-xl p-8 hover:bg-[white] shadow-2xl group cursor-pointer duration-200" data-aos="fade-up">
                  <i className="fa fa-database text-4xl text-[white] group-hover:text-[#1a4678]"></i>
                  <h4 className="text-[22px] font-semibold mt-2 text-[white]  group-hover:text-[#1a4678]">
                    Database Handling
                  </h4>
                  <p className="mt-1 text-[#cdcccc]  group-hover:text-[#696969]">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Exercitationem fuga officia dignissimos molestias
                    pariatunis, cum necelore id obcaecati!
                  </p>
                  <button className="text-[#ffffff]  rounded-xl mt-4 hover:bg-[] group-hover:text-[#1a4678]">
                    Learn More{" "}
                    <i className="fa fa-long-arrow-right ml-1 relative top-[1px]"></i>
                  </button>
                </div>

                <div className="bg-[#1a4678] w-[300px] rounded-xl p-8 hover:bg-[white] shadow-2xl group cursor-pointer duration-200" data-aos="fade-up">
                  <i className="fa fa-database text-4xl text-[white] group-hover:text-[#1a4678]"></i>
                  <h4 className="text-[22px] font-semibold mt-2 text-[white]  group-hover:text-[#1a4678]">
                    Database Handling
                  </h4>
                  <p className="mt-1 text-[#cdcccc]  group-hover:text-[#696969]">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Exercitationem fuga officia dignissimos molestias
                    pariatunis, cum necelore id obcaecati!
                  </p>
                  <button className="text-[#ffffff]  rounded-xl mt-4 hover:bg-[] group-hover:text-[#1a4678]">
                    Learn More{" "}
                    <i className="fa fa-long-arrow-right ml-1 relative top-[1px]"></i>
                  </button>
                </div>

                <div className="bg-[#1a4678] w-[300px] rounded-xl p-8 hover:bg-[white] shadow-2xl group cursor-pointer duration-200" data-aos="fade-up">
                  <i className="fa fa-database text-4xl text-[white] group-hover:text-[#1a4678]"></i>
                  <h4 className="text-[22px] font-semibold mt-2 text-[white]  group-hover:text-[#1a4678]">
                    Database Handling
                  </h4>
                  <p className="mt-1 text-[#cdcccc]  group-hover:text-[#696969]">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Exercitationem fuga officia dignissimos molestias
                    pariatunis, cum necelore id obcaecati!
                  </p>
                  <button className="text-[#ffffff]  rounded-xl mt-4 hover:bg-[] group-hover:text-[#1a4678]">
                    Learn More{" "}
                    <i className="fa fa-long-arrow-right ml-1 relative top-[1px]"></i>
                  </button>
                </div>

                <div className="bg-[#1a4678] w-[300px] rounded-xl p-8 hover:bg-[white] shadow-2xl group cursor-pointer duration-200" data-aos="fade-up">
                  <i className="fa fa-database text-4xl text-[white] group-hover:text-[#1a4678]"></i>
                  <h4 className="text-[22px] font-semibold mt-2 text-[white]  group-hover:text-[#1a4678]">
                    Database Handling
                  </h4>
                  <p className="mt-1 text-[#cdcccc]  group-hover:text-[#696969]">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Exercitationem fuga officia dignissimos molestias
                    pariatunis, cum necelore id obcaecati!
                  </p>
                  <button className="text-[#ffffff]  rounded-xl mt-4 hover:bg-[] group-hover:text-[#1a4678]">
                    Learn More{" "}
                    <i className="fa fa-long-arrow-right ml-1 relative top-[1px]"></i>
                  </button>
                </div>

                <div className="bg-[#1a4678] w-[300px] rounded-xl p-8 hover:bg-[white] shadow-2xl group cursor-pointer duration-200" data-aos="fade-up">
                  <i className="fa fa-database text-4xl text-[white] group-hover:text-[#1a4678]"></i>
                  <h4 className="text-[22px] font-semibold mt-2 text-[white]  group-hover:text-[#1a4678]">
                    Database Handling
                  </h4>
                  <p className="mt-1 text-[#cdcccc]  group-hover:text-[#696969]">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Exercitationem fuga officia dignissimos molestias
                    pariatunis, cum necelore id obcaecati!
                  </p>
                  <button className="text-[#ffffff]  rounded-xl mt-4 hover:bg-[] group-hover:text-[#1a4678]">
                    Learn More{" "}
                    <i className="fa fa-long-arrow-right ml-1 relative top-[1px]"></i>
                  </button>
                </div>

                <div className="bg-[#1a4678] w-[300px] rounded-xl p-8 hover:bg-[white] shadow-2xl group cursor-pointer duration-200" data-aos="fade-up">
                  <i className="fa fa-database text-4xl text-[white] group-hover:text-[#1a4678]"></i>
                  <h4 className="text-[22px] font-semibold mt-2 text-[white]  group-hover:text-[#1a4678]">
                    Database Handling
                  </h4>
                  <p className="mt-1 text-[#cdcccc]  group-hover:text-[#696969]">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Exercitationem fuga officia dignissimos molestias
                    pariatunis, cum necelore id obcaecati!
                  </p>
                  <button className="text-[#ffffff]  rounded-xl mt-4 hover:bg-[] group-hover:text-[#1a4678]">
                    Learn More{" "}
                    <i className="fa fa-long-arrow-right ml-1 relative top-[1px]"></i>
                  </button>
                </div>
              </div>
            </div>
          </div> */}

          {/* Services */}
          <div className="lg:p-10 md:p-6 p-4 py-10 mt-10 bg-[#e6e6fa]">
            <div
              className="text-center mt-16 flex flex-col items-center justify-center"
              data-aos="fade-up"
            >
              <p
                className="text-[#0085ff] text-[17px] font-medium text-center mb-1"
                data-aos="fade-up"
              >
                What we provide
              </p>
              <h2 className="md:text-4xl text-3xl font-medium tracking-wide text-black">
                {" "}
                Our Awesome{" "}
                <span className="font-bold text-[#0085ff]">Services</span>
              </h2>
              <p className="text-[#535353] mt-4 tracking-wider md:w-[600px] w-[95%]">
                We create custom solutions designed to meet your unique business
                needs, ensuring our products help you achieve your specific
                goals.
              </p>
              <div className="relative bg-[#0085ff] h-[6px] w-[140px] mt-4 rounded-md overflow-hidden">
                <div className="absolute h-[6px] w-[10px] bg-white top-0 left-0 rounded-md heading-underline"></div>
              </div>
            </div>
            <div className="mt-20 justify-center flex flex-wrap gap-16">
              {services.map((item) => {
                return (
                  <div
                    className="md:w-[350px] w-[320px] h-[270px] flex flex-col items-center bg-[#f6fafd] py-6 md:px-4 px-3 shadow-lg border rounded-md hover:bg-[#0085ff] hover:text-white group"
                    data-aos="zoom-in-up"
                  >
                    <div
                      className={`border-solid rounded-tl-[25px] rounded-br-[25px] h-[75px] w-[75px] flex justify-center items-center group-hover:bg-[white] group-hover:border-solid`}
                      style={{ backgroundColor: `${item.color}` }}
                    >
                      <i className={`${item.icon} text-white text-2xl`}></i>
                    </div>
                    <h5 className="text-xl font-semibold mt-3">{item.title}</h5>
                    <p className="text-[#535353] group-hover:text-white tracking-wider mt-1 text-center">
                      {item.description}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="mt-8 text-center text-lg">
              <button
                className="bg-[#0085ff] text-white rounded-sm text-[14px] md:text-base md:h-[38px] h-[36px] w-[140px] md:w-[150px] hover:bg-gradient-to-tr to-[#0085ff] from-[#5dabf4]"
                onClick={() => {
                  navigate("/our-services");
                }}
              >
                View All{" "}
              </button>
            </div>
          </div>

          {/* Why Choose Us */}
          <div className="lg:p-10 md:p-6 p-4">
            <div
              className="text-center mt-16 flex flex-col items-center justify-center"
              data-aos="fade-up"
            >
              <p
                className="text-[#0085ff] text-[17px] font-medium text-center mb-1"
                data-aos="fade-up"
              >
                Why Choose Us
              </p>
              <h2 className="md:text-4xl text-3xl font-medium tracking-wide text-black">
                {" "}
                Unlock <span className="font-bold text-[#0085ff]">
                  Revenue
                </span>{" "}
                Growth <br /> for your{" "}
                <span className="font-bold ">Business</span>
              </h2>
              <div className="relative bg-[#0085ff] h-[6px] w-[140px] mt-2 rounded-md overflow-hidden">
                <div className="absolute h-[6px] w-[10px] bg-white top-0 left-0 rounded-md heading-underline"></div>
              </div>
            </div>
            <div className="flex lg:flex-row flex-col justify-center gap-10 md:mt-24 mt-12">
              <div className="flex flex-col justify-center items-center w-full">
                <div className="w-[280px]" data-aos="fade-left">
                  <div className="flex items-center gap-4 ">
                    <div className="bg-[#0085ff] h-[32px] w-[32px] rounded-full text-white flex justify-center items-center">
                      <i className="fa fa-check"></i>
                    </div>
                    <h3 className="text-xl md:font-semibold font-medium">
                      High Standard
                    </h3>
                  </div>
                  <div className="mt-1">
                    <p className="text-[#535353] tracking-wide">
                      We ensure that our solutions are reliable, efficient, and
                      meet the highest levels of quality and performance.
                    </p>
                  </div>
                </div>
                <div className="w-[280px] mt-12" data-aos="fade-left">
                  <div className="flex items-center gap-4 ">
                    <div className="bg-[#0085ff] h-[32px] w-[32px] rounded-full text-white flex justify-center items-center">
                      <i className="fa fa-check"></i>
                    </div>
                    <h3 className="text-xl md:font-semibold font-medium">
                      Focus On People
                    </h3>
                  </div>
                  <div className="mt-1">
                    <p className="text-[#535353] tracking-wide">
                      We prioritize understanding the needs and challenges of
                      our clients, ensuring that our solutions are
                      user-friendly.
                    </p>
                  </div>
                </div>
                <div className="w-[280px] mt-12" data-aos="fade-left">
                  <div className="flex items-center gap-4 ">
                    <div className="bg-[#0085ff] h-[32px] w-[32px] rounded-full text-white flex justify-center items-center">
                      <i className="fa fa-check"></i>
                    </div>
                    <h3 className="text-xl md:font-semibold font-medium">
                      Different Thinking
                    </h3>
                  </div>
                  <div className="mt-1">
                    <p className="text-[#535353] tracking-wide">
                      Our team explores new ideas and approaches to deliver
                      cutting-edge solutions .
                    </p>
                  </div>
                </div>
                <div className="w-[280px] mt-12" data-aos="fade-left">
                  <div className="flex items-center gap-4 ">
                    <div className="bg-[#0085ff] h-[32px] w-[32px] rounded-full text-white flex justify-center items-center">
                      <i className="fa fa-check"></i>
                    </div>
                    <h3 className="text-xl md:font-semibold font-medium">
                      Expert Team
                    </h3>
                  </div>
                  <div className="mt-1">
                    <p className="text-[#535353] tracking-wide">
                      Our expert team comprises skilled professionals with
                      diverse backgrounds and extensive experience.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-col justify-center items-center w-full"
                data-aos="fade-up"
              >
                <img src={girlImage} alt="" />
              </div>
              <div className="flex-col flex justify-center items-center gap-6 w-full">
                <div
                  className="flex flex-col items-center justify-center p-8 shadow-2xl w-[220px]"
                  data-aos="fade-right"
                >
                  <i className="fa fa-database text-[#0085ff] text-4xl"></i>
                  <h6 className="text-3xl font-bold mt-2">134 +</h6>
                  <span>Projects Done</span>
                </div>
                <div
                  className="flex flex-col items-center justify-center p-8 shadow-2xl w-[220px]"
                  data-aos="fade-right"
                >
                  <i className="fa fa-users text-[#0085ff] text-4xl"></i>
                  <h6 className="text-3xl font-bold mt-2">460 +</h6>
                  <span>Happy Client</span>
                </div>
                <div
                  className="flex flex-col items-center justify-center p-8 shadow-2xl w-[220px]"
                  data-aos="fade-right"
                >
                  <i className="fa fa-line-chart text-[#0085ff] text-4xl"></i>
                  <h6 className="text-3xl font-bold mt-2">25 +</h6>
                  <span>Year's Experience</span>
                </div>
              </div>
            </div>
          </div>

          {/* Recent Projects */}
          <div className="lg:p-20 py-20 border-0">
            <div
              className="text-center mt-16 flex flex-col items-center justify-center"
              data-aos="fade-up"
            >
              <p
                className="text-[#0085ff] text-[17px] font-medium text-center mb-1"
                data-aos="fade-up"
              >
                Our Work
              </p>
              <h2 className="md:text-4xl text-3xl font-medium tracking-wide text-black">
                {" "}
                Our Recent{" "}
                <span className="font-bold text-[#0085ff]">Project</span>
              </h2>
              <p className="text-[#535353] mt-4 tracking-wider md:w-[550px] w-[95%]">
                Successfully completed projects that showcase our expertise and
                deliver tangible results.
              </p>
              <div className="relative bg-[#0085ff] h-[6px] w-[140px] mt-4 rounded-md overflow-hidden">
                <div className="absolute h-[6px] w-[10px] bg-white top-0 left-0 rounded-md heading-underline"></div>
              </div>
            </div>
            <div className="flex justify-center md:mt-20 mt-12 select-none">
              <div className="lg:w-[90%] md:w-[90%] w-[96%] flex flex-wrap justify-around gap-10">
                {/* <Carousel
                  withIndicators
                  height={200}
                  slideSize={{ base: "80%", sm: "50%", md: "33.333333%" }}
                  slideGap={{ base: 0, sm: "lg" }}
                  loop
                  align="center"
                > */}
                {projects.map((project) => {
                  return (
                    // <Carousel.Slide>
                    <div
                      className="h-[400px] w-[320px] relative group"
                      style={{ backgroundImage: `url(${project.image})` }}
                      data-aos="zoom-in-up"
                    >
                      <div className="h-[400px] w-[320px] absolute px-6 py-10 bg-[rgba(73,71,71,0.8)] text-white group-hover:block hidden">
                        <h4 className="text-2xl font-semibold">
                          {project.title}
                        </h4>
                        <div className="">
                          <p className="mt-4">{project.description}</p>
                        </div>
                        {/* <div className="flex-1 flex-col justify-end">
                              <button className="border-2 text-sm h-[35px] w-[120px] border-[#0085ff] mt-6 text-white bg-[#0085ff]">
                                Read More
                              </button>
                            </div> */}
                      </div>
                    </div>
                    // </Carousel.Slide>
                  );
                })}

                {/* ...other slides */}
                {/* </Carousel> */}
              </div>
            </div>
          </div>
        </div>
        <MoveToTopButton />
      </Layout>
    </div>
  );
}
