import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS file for AOS

import serviceBoy from "../../assets/images/serviceBoy.png";
import MoveToTopButton from "../../components/moveToTopButton/MoveToTopButton";

export default function Service() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000, // Animation duration
      offset: 50, // Offset (in pixels) from the original trigger point
      delay: 100, // Delay (in milliseconds) for the animation to start
    });
  }, []);

  const services = [
    {
      title: "Mobile App Development",
      description:
        "Creating mobile applications for Android and iOS platforms, designed for excellent user experiences and high performance.",
      icon: "fa fa-mobile",
      color: "#D0E3F4", // Soft Light Blue
    },
    {
      title: "Web Development",
      description:
        "Building responsive and modern websites tailored to your business needs, ensuring a seamless user experience.",
      icon: "fa fa-code",
      color: "#C5E3A0", // Soft Green
    },
    {
      title: "Marketing Strategy",
      description:
        "Crafting comprehensive marketing strategies to boost your brand's visibility and reach your target audience effectively.",
      icon: "fa fa-pie-chart",
      color: "#6EC1E4", // Soft Blue
    },
    {
      title: "Business Development Strategy",
      description:
        "Designing strategic plans to drive business growth, enhance market presence, and increase revenue streams.",
      icon: "fa fa-cogs",
      color: "#F6C1C1", // Soft Pink
    },
    {
      title: "Firm Differentiation Strategy",
      description:
        "Creating unique value propositions to set your business apart from competitors and attract your ideal customers.",
      icon: "fa fa-shopping-cart",
      color: "#F7E1A0", // Soft Yellow
    },
    {
      title: "Social Media Planning",
      description:
        "Developing strategic social media plans to engage your audience, build brand loyalty, and increase online presence.",
      icon: "fa fa-search",
      color: "#B8E2D9", // Soft Teal
    },
    {
      title: "Competitor Analysis",
      description:
        "Conducting thorough competitor analysis to identify strengths, weaknesses, and market opportunities.",
      icon: "fa fa-bar-chart",
      color: "#6EC1E4", // Soft Blue
    },
    {
      title: "SWOT Analysis",
      description:
        "Performing SWOT analysis to evaluate strengths, weaknesses, opportunities, and threats for strategic planning.",
      icon: "fa fa-balance-scale",
      color: "#F6C1C1", // Soft Pink
    },
    {
      title: "Market Analysis",
      description:
        "Analyzing market trends and consumer behavior to inform business strategies and decision-making.",
      icon: "fa fa-area-chart",
      color: "#F7E1A0", // Soft Yellow
    },
    {
      title: "Developing a Brand Identity",
      description:
        "Creating a unique brand identity that resonates with your target audience and sets you apart from competitors.",
      icon: "fa fa-id-badge",
      color: "#B8E2D9", // Soft Teal
    },
    {
      title: "Content Planning",
      description:
        "Implementing effective content strategies to deliver valuable, relevant content that resonates with your audience.",
      icon: "fa fa-file-o",
      color: "#C5E3A0", // Soft Green
    },
    {
      title: "Media Relationship Planning",
      description:
        "Building and maintaining strong relationships with media to enhance your brand's visibility and reputation.",
      icon: "fa fa-newspaper-o",
      color: "#FFD3B4", // Soft Peach
    },
    {
      title: "Step By Step Processes",
      description:
        "Developing detailed step-by-step processes to ensure successful project execution and goal achievement.",
      icon: "fa fa-list-ol",
      color: "#D3C6F4", // Soft Purple
    },
    {
      title: "Setting Marketing Budgets",
      description:
        "Allocating and managing marketing budgets effectively to maximize ROI and achieve business objectives.",
      icon: "fa fa-usd",
      color: "#FFB6C1", // Light Pink
    },
    {
      title: "Setting KPIs and Metrics",
      description:
        "Defining key performance indicators and metrics to measure the success of your marketing efforts.",
      icon: "fa fa-bar-chart",
      color: "#98FB98", // Pale Green
    },
    {
      title: "Recommendations",
      description:
        "Providing expert recommendations to enhance your marketing strategies and drive business growth.",
      icon: "fa fa-thumbs-up",
      color: "#ADD8E6", // Light Blue
    },
    {
      title: "New Service Campaign Launches",
      description:
        "Planning and executing campaigns for the successful launch of new services, ensuring maximum impact.",
      icon: "fa fa-rocket",
      color: "#FFD700", // Gold
    },
    {
      title: "SEO",
      description:
        "Enhancing your website's search engine visibility to drive organic traffic and improve your online ranking.",
      icon: "fa fa-search",
      color: "#C5E3A0", // Soft Green
    },
    {
      title: "SEM",
      description:
        "Implementing search engine marketing strategies to increase your visibility and drive targeted traffic.",
      icon: "fa fa-search-plus",
      color: "#D0E3F4", // Soft Light Blue
    },
    {
      title: "Digital Ads (PPC)",
      description:
        "Creating and managing pay-per-click campaigns to drive targeted traffic and achieve marketing goals.",
      icon: "fa fa-bullseye",
      color: "#FFB6C1", // Light Pink
    },
    {
      title: "Offline Advertising",
      description:
        "Planning and executing offline advertising strategies to reach your target audience through traditional media.",
      icon: "fa fa-desktop",
      color: "#FFD3B4", // Soft Peach
    },
    {
      title: "Analytics/Audit",
      description:
        "Conducting thorough analytics and audits to evaluate the effectiveness of your marketing strategies.",
      icon: "fa fa-pie-chart",
      color: "#6EC1E4", // Soft Blue
    },
    {
      title: "Conversion Rate Optimisation",
      description:
        "Optimizing your website and marketing strategies to increase conversion rates and drive business growth.",
      icon: "fa fa-percent",
      color: "#F6C1C1", // Soft Pink
    },
    {
      title: "Legal Directory Consulting",
      description:
        "Providing expert consulting services for managing and optimizing your presence in legal directories.",
      icon: "fa fa-gavel",
      color: "#F7E1A0", // Soft Yellow
    },
    {
      title: "Event/Webinar Management",
      description:
        "Planning and managing events and webinars to engage your audience and promote your brand effectively.",
      icon: "fa fa-calendar-o",
      color: "#B8E2D9", // Soft Teal
    },
    {
      title: "CRM Implementation",
      description:
        "Implementing customer relationship management systems to streamline your operations and improve customer interactions.",
      icon: "fa fa-users",
      color: "#D0E3F4", // Soft Light Blue
    },
    {
      title: "New Client Acquisition Strategies",
      description:
        "Developing strategies to attract new clients and expand your customer base effectively.",
      icon: "fa fa-user-plus",
      color: "#F6C1C1", // Soft Pink
    },
    {
      title: "Client Retention Strategies",
      description:
        "Creating effective client retention strategies to maintain long-term relationships and ensure customer loyalty.",
      icon: "fa fa-handshake-o",
      color: "#F7E1A0", // Soft Yellow
    },
    {
      title: "Commercial Training",
      description:
        "Providing commercial training to enhance your team's skills and knowledge, driving business success.",
      icon: "fa fa-shopping-cart",
      color: "#B8E2D9", // Soft Teal
    },
    {
      title: "KPI and Metric Setting",
      description:
        "Defining key performance indicators and metrics to measure the success of your marketing efforts.",
      icon: "fa fa-line-chart",
      color: "#98FB98", // Pale Green
    },
    {
      title: "Personal Training",
      description:
        "Offering personalized training programs to develop individual skills and improve performance.",
      icon: "fa fa-users",
      color: "#FFD700", // Gold
    },
    {
      title: "Developing Brand Identity",
      description:
        "Creating a unique brand identity that resonates with your target audience and sets you apart from competitors.",
      icon: "fa fa-id-badge",
      color: "#F6C1C1", // Soft Pink
    },
    {
      title: "Developing Brand Content",
      description:
        "Crafting compelling brand content that communicates your value proposition and engages your audience.",
      icon: "fa fa-pencil",
      color: "#F7E1A0", // Soft Yellow
    },
    {
      title: "Social Media Calendar",
      description:
        "Creating a social media calendar to plan and organize your posts for maximum engagement and reach.",
      icon: "fa fa-calendar",
      color: "#FFD3B4", // Soft Peach
    },
    {
      title: "Editorial Calendar",
      description:
        "Creating an editorial calendar to plan, organize, and schedule your content production effectively.",
      icon: "fa fa-calendar-o",
      color: "#FFD3B4", // Soft Peach
    },
    {
      title: "Trending Analysis",
      description:
        "Analyzing current trends to keep your strategies relevant and ahead of the competition.",
      icon: "fa fa-line-chart",
      color: "#6EC1E4", // Soft Blue
    },
    // {
    //   title: "Content Production",
    //   description:
    //     "Producing high-quality content that aligns with your brand and engages your target audience effectively.",
    //   icon: "fa fa-pen-fancy",
    //   color: "#F7E1A0", // Soft Yellow
    // },
  ];
  

  
    
  
  return (
    <div>
      <Layout>
        <div className="">
          {/* <div className="bg-[#e2e6e9] py-4 md:pl-8 pl-3 text-sm md:text-base tracking-wide">
            <span>
              <Link to="/">Home</Link> /{" "}
              <span className="text-[#0b3b72]">Services</span>
            </span>
          </div> */}
          {/* Services */}
          <div className="lg:px-10 md:p-6 p-4 mt-0">
            <div
              className="text-center mt-12 flex flex-col items-center justify-center"
              data-aos="fade-up"
            >
              <p
                className="text-[#0085ff] text-[17px] font-medium text-center mb-1"
                data-aos="fade-up"
              >
                What we provide
              </p>
              <h2 className="md:text-4xl text-3xl font-medium tracking-wide text-black">
                {" "}
                Our Awesome{" "}
                <span className="font-bold text-[#0085ff]">Services</span>
              </h2>
              <p className="text-[#535353] mt-4 tracking-wider md:w-[600px] w-[95%]">
                We create custom solutions designed to meet your unique business
                needs, ensuring our products help you achieve your specific
                goals.
              </p>
              <div className="relative bg-[#0085ff] h-[6px] w-[140px] mt-4 rounded-md overflow-hidden">
                <div className="absolute h-[6px] w-[10px] bg-white top-0 left-0 rounded-md heading-underline"></div>
              </div>
            </div>
            <div className="mt-20 justify-center flex flex-wrap gap-16">
              {services.map((item) => {
                return (
                  <div
                    className="md:w-[350px] w-[320px] h-[270px] flex flex-col items-center bg-[white] py-6 md:px-4 px-3 shadow-lg border rounded-md hover:bg-[#0085ff] hover:text-white group"
                    data-aos="zoom-in-up"
                  >
                    <div
                      className={`border-solid rounded-tl-[25px] rounded-br-[25px] h-[75px] w-[75px] flex justify-center items-center group-hover:bg-[white] group-hover:border-solid`}
                      style={{ backgroundColor: `${item.color}` }}
                    >
                      <i className={`${item.icon} text-white text-2xl`}></i>
                    </div>
                    <h5 className="text-xl font-semibold mt-3">{item.title}</h5>
                    <p className="text-[#535353] group-hover:text-white tracking-wider mt-1 text-center">
                      {item.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Service Call */}
          <div className="flex lg:flex-row flex-col-reverse gap-6 justify-around items-center lg:h-[800px] h-auto p-10">
            <div className="lg:w-[40%]">
              <p
                className="text-[#0085ff] text-[17px] font-medium"
                data-aos="fade-right"
              >
                Get In Touch
              </p>
              <div className="">
                <h2
                  className="md:text-5xl text-4xl font-normal tracking-wide mt-2"
                  data-aos="fade-right"
                >
                  Contact us{" "}
                  <span className="font-bold leading-[58px] text-[#0085ff]">
                    Today free{" "}
                  </span>
                  for <br /> a consultation
                </h2>
                <div className="relative bg-[#0085ff] h-[6px] w-[140px] mt-2 rounded-md overflow-hidden">
                  <div className="absolute h-[6px] w-[10px] bg-white top-0 left-0 rounded-md heading-underline"></div>
                </div>
              </div>
              <p
                className="text-[#535353] mt-4 tracking-wide"
                data-aos="fade-right"
              >
                Ready to take your business to the next level with innovative IT
                solutions? Contact us today for a free consultation. Our team at
                Lakecity Web is eager to understand your unique needs and
                challenges. We’ll provide expert insights and discuss how our
                tailored solutions can drive success for your business.
              </p>
              <p
                className="text-[#535353] mt-4 tracking-wide"
                data-aos="fade-right"
              >
                Reach out to us via phone, email, or our contact form, and let’s
                start a conversation about how we can help you achieve your
                goals. Don’t miss this opportunity to explore how our 25 years
                of experience can benefit your organization.
              </p>
              <button
                className="text-[#0085ff] border-2 h-[40px] w-[160px] border-[#0085ff] mt-10 hover:text-white hover:bg-[#0085ff]"
                data-aos="fade-right"
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                Let's Talk
              </button>
            </div>
            <div className="lg:w-[40%] flex">
              <div className="md:h-[500px] h-[280px]" data-aos="fade-right">
                <div className="relative overflow-hidden">
                  <img
                    className="hover:scale-110 duration-300 md:h-[500px] h-[280px]"
                    src={serviceBoy}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <MoveToTopButton />
      </Layout>
    </div>
  );
}
