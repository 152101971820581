import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS file for AOS
import MoveToTopButton from "../../components/moveToTopButton/MoveToTopButton";

export default function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000, // Animation duration
      offset: 50, // Offset (in pixels) from the original trigger point
      delay: 100, // Delay (in milliseconds) for the animation to start
    });
  }, []);

  const sendMessage = (e) => {
    e.preventDefault();
    // return;
    try {
      const whatsappURL =
        "https://api.whatsapp.com/send?phone=+918003990546&text=";
      const formattedMessage = `Name: ${name}\nEmail: ${email}\nSubject: ${subject}\nMessage: ${message}`;
      const encodedMessage = encodeURIComponent(formattedMessage);
      const finalURL = whatsappURL + encodedMessage;

      // Redirect user to WhatsApp message URL
      window.location.href = finalURL;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Layout>
        <div className="">
          {/* <div className="bg-[#e2e6e9] py-4 md:pl-8 pl-3 text-sm md:text-base tracking-wide">
            <span>
              <Link to="/">Home</Link> /{" "}
              <span className="text-[#0b3b72]">Contact Us</span>
            </span>
          </div> */}

          {/* What set us apart */}
          <div className="flex md:flex-row flex-col justify-around items-start lg:mt-0 mt-0">
            {/* Content */}
            <div className="lg:w-[50%] w-[96%] lg:h-[700px] h-auto flex flex-col justify-center lg:p-16 p-4">
              <div
                className="text-center mt-12 flex flex-col items-start justify-center"
                data-aos="fade-up"
              >
                {/* <p
                className="text-[#0085ff] text-[17px] font-medium mb-1"
                data-aos="fade-up"
              >
                Get In Touch
              </p> */}
                <h2 className="md:text-4xl text-3xl font-medium tracking-wide text-black">
                  {" "}
                  What Set Us{" "}
                  <span className="font-bold text-[#0085ff]">Apart</span>
                </h2>
                <p className="text-[#535353] text-start mt-4 tracking-wider md:w-[600px] w-[95%]">
                  Reach out today and let our experts provide you with tailored
                  solutions to drive your business forward.
                </p>
                <div className="relative bg-[#0085ff] h-[6px] w-[140px] mt-4 rounded-md overflow-hidden">
                  <div className="absolute h-[6px] w-[10px] bg-white top-0 left-0 rounded-md heading-underline"></div>
                </div>
              </div>
              <div className="flex flex-col mt-10">
                <div className="flex lg:flex-row flex-col flex-wrap md:gap-6 justify-between ">
                  <div className="w-[200px] h-[120px]" data-aos="fade-right">
                    <h6 className="font-semibold text-xl">Address</h6>
                    <p className="mt-4">
                      213-A, Sardarpura <br />
                      Udaipur - Rajasthan
                    </p>
                  </div>
                  <div className="w-[200px]  h-[120px]" data-aos="fade-right">
                    <h6 className="font-semibold text-xl">Working Hours</h6>
                    <p className="mt-4">
                      Monday to Friday: 9AM – 8PM <br />
                      Week-End: 10AM – 5PM
                    </p>
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col flex-wrap md:gap-10 justify-between md:mt-0 mt-6">
                  <div className="w-[200px]  h-[120px]" data-aos="fade-right">
                    <h6 className="font-semibold text-xl">Contact</h6>
                    <p className="mt-4">
                      info@lakecityweb.com <br />
                      +91 8003990546
                    </p>
                  </div>
                  <div className="w-[200px]  h-[120px]" data-aos="fade-right">
                    <h6 className="font-semibold text-xl">Socials</h6>
                    <div className="mt-4 flex flex-row gap-4 text-2xl">
                      {/* <i className="fa fa-facebook"></i> */}
                      <Link
                        to="https://www.linkedin.com/company/lakecitywebprivatelimited/about/?viewAsMember=true"
                        target="_blank"
                      >
                        {" "}
                        <i className="fa fa-linkedin"></i>
                      </Link>
                      <Link to="" target="_blank">
                        {" "}
                        <i className="fa fa-whatsapp"></i>
                      </Link>
                      {/* <i className="fa fa-instagram"></i> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-[50%] w-[96%] lg:h-[700px] h-auto flex flex-col justify-center md:p-16 p-4">
              <div className="">
                <h2
                  className="text-[32px] font-semibold leading-[75px]"
                  data-aos="fade-left"
                >
                  Get In <span className="font-bold text-[#0085ff]">Touch</span>
                </h2>
              </div>
              <div className="mt-4">
                <form action="" onSubmit={sendMessage}>
                  <div className="flex md:flex-row flex-col md:gap-0 gap-3 justify-between">
                    <input
                      type="text"
                      className="h-[50px] md:w-[280px] w-full rounded-lg border shadow-sm  md:pl-5 pl-3 outline-none"
                      placeholder="Your Name"
                      data-aos="fade-left"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                    />
                    <input
                      type="text"
                      className="h-[50px] md:w-[280px] w-full rounded-lg border shadow-sm md:pl-5 pl-3 outline-none"
                      placeholder="Your Email"
                      data-aos="fade-left"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="mt-3">
                    <input
                      type="text"
                      className="h-[50px] w-full rounded-lg border shadow-sm md:pl-5 pl-3 outline-none"
                      placeholder="Your Subject"
                      data-aos="fade-left"
                      value={subject}
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="mt-3">
                    <textarea
                      type="text"
                      className="h-[200px] w-full rounded-md border shadow-sm resize-none md:p-5 p-3 outline-none"
                      placeholder="Your Message"
                      data-aos="fade-left"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      required
                    ></textarea>
                  </div>
                  <div className="mt-2" data-aos="fade-left">
                    <button className="border-2 text-sm h-[40px] w-[160px] border-[#0085ff] mt-6 hover:text-white text-[#0085ff] hover:bg-[#0085ff]">
                      Submit Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <MoveToTopButton />
      </Layout>
    </div>
  );
}
