import './App.css';
import AllRoutes from './routes/AllRoutes';
import '@mantine/carousel/styles.css';

function App() {
  return (
    <div className=" overflow-hidden bg-[white]">
      <AllRoutes />
    </div>
  );
}

export default App;
